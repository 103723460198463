import {
  GET_REGIONS_FAILURE,
  GET_REGIONS_SUCCESS,
  IMPORT_REGIONS_FAILURE,
  IMPORT_REGIONS_SUCCESS,
} from "@/constants/actionTypes";
import {RegionsType} from "app/types";
import {RegionsActionsTypes} from "@/actions/regions.actions";

type InitialStateType = {
  regions: RegionsType[];
  error: string;
  regionsImportStatus: number;
  regionsImportError: string;
};

const initialState: InitialStateType = {
  regions: [],
  error: null,
  regionsImportStatus: 0,
  regionsImportError: null,
};

const regions = (
  state = initialState,
  action: RegionsActionsTypes
): InitialStateType => {
  switch (action.type) {
    case GET_REGIONS_SUCCESS:
      return {
        ...state,
        regions: action.payload.regions,
        error: null,
      };
    case GET_REGIONS_FAILURE:
      return {
        ...state,
        regions: [],
        error: action.payload.error,
      };
    case IMPORT_REGIONS_SUCCESS:
      return {
        ...state,
        regionsImportStatus: action.payload.regionsImportStatus,
        regionsImportError: null,
      };
    case IMPORT_REGIONS_FAILURE:
      return {
        ...state,
        regionsImportStatus: 0,
        regionsImportError: action.payload.regionsImportError,
      };
    default:
      return state;
  }
};

export default regions;
