export default {
  grid: {
    columns: {
      queries: ["(min-width: 768px)"],
      values: [{ columns: 12, margin: 36, gutter: 36 }],
      defaultValue: { columns: 2, margin: 18, gutter: 11 },
    },
    // rows: {
    //   queries: ["(min-width: 768px)"],
    //   values: [{ height: 120, offset: 45 }],
    //   defaultValue: { height: 120, offset: 15 },
    // },
  },
};
