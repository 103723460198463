import {
  GET_MESSAGE_SUCCESS,
  GET_MESSAGE_FAILURE,
  GET_MESSAGES_LIST_SUCCESS,
  GET_MESSAGES_LIST_FAILURE,
  GET_MESSAGES_SERVICE_SETTINGS_SUCCESS,
  GET_MESSAGES_SERVICE_SETTINGS_FAILURE,
  SAVE_MESSAGES_SERVICE_SETTINGS_SUCCESS,
  SAVE_MESSAGES_SERVICE_SETTINGS_FAILURE,
} from "@/constants/actionTypes";
import {
  MessagesActionsTypes
} from "@/actions/messages.actions";
import {
  MessageDataType,
  MessagesListType,
  MessagesSettingsDataType,
} from "app/types";

export type InitialStateType = {
  saveMessageServiceSettingsStatus: number;
  messageServiceSettingsData: MessagesSettingsDataType;
  messagesList: MessagesListType[];
  messagesPages: number;
  messageData: MessageDataType;
  error: string;
};

const initialState: InitialStateType = {
  saveMessageServiceSettingsStatus: 0,
  messageServiceSettingsData: null,
  messagesList: [],
  messagesPages: 0,
  messageData: null,
  error: null,
};

const messages = (
  state = initialState,
  action: MessagesActionsTypes
): InitialStateType => {
  switch (action.type) {
    case GET_MESSAGE_SUCCESS:
      return {
        ...state,
        messageData: action.payload.messageData,
        error: null,
      };
    case GET_MESSAGE_FAILURE:
      return {
        ...state,
        messageData: null,
        error: action.payload.error,
      };
    case GET_MESSAGES_LIST_SUCCESS:
      return {
        ...state,
        messagesList: action.payload.messagesListData.records,
        messagesPages: action.payload.messagesListData.pages,
        error: null,
      };
    case GET_MESSAGES_LIST_FAILURE:
      return {
        ...state,
        messagesList: [],
        messagesPages: 0,
        error: action.payload.error,
      };
    case GET_MESSAGES_SERVICE_SETTINGS_SUCCESS:
      return {
        ...state,
        messageServiceSettingsData: action.payload.messageServiceSettingsData,
        error: null,
      };
    case GET_MESSAGES_SERVICE_SETTINGS_FAILURE:
      return {
        ...state,
        messageServiceSettingsData: null,
        error: action.payload.error,
      };
    case SAVE_MESSAGES_SERVICE_SETTINGS_SUCCESS:
      return {
        ...state,
        saveMessageServiceSettingsStatus: action.payload.saveMessageServiceSettingsStatus,
        error: null,
      };
    case SAVE_MESSAGES_SERVICE_SETTINGS_FAILURE:
      return {
        ...state,
        saveMessageServiceSettingsStatus: 0,
        error: action.payload.error,
      };
    default:
      return state;
  }
};

export default messages;
