import { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

const useAOS = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      ease: "ease-out",
      disableMutationObserver: false,
      anchorPlacement: "top-bottom",
    });
  }, []);
};

export default useAOS;
