import axios from "axios";
import { logout, refreshAuthToken } from "@/actions/account.actions";
import { v4 as uuidv4 } from "uuid";
import { message } from "antd";

const paramsToQueryString = (body) => {
  const params = new URLSearchParams();

  for (const key in body) {
    if (body[key]) {
      params.append(key, body[key]);
    }
  }

  return params.toString();
};

const refreshToken =
  typeof window !== "undefined" &&
  window.localStorage &&
  localStorage.getItem("refreshToken");

export const setupResponseInterceptor = (store) =>
  axios.interceptors.response.use(null, (error) => {
    const serverErrors = [500, 502, 503, 504, 505];
    const clientErrors = [400, 403, 404];

    if (error?.response?.status === 401 && error?.response?.message === 'The access token expired') {
      store.dispatch(refreshAuthToken(refreshToken));
    }

    if (error?.response?.status === 401) {
      store.dispatch(logout());
    }

   if (clientErrors.includes(error?.response?.status)) {
     if (error?.response?.data?.detail) {
       message.error(error?.response?.data?.detail, 4);
     } else if (error?.response?.status === 403) {
       message.error("Недостаточно прав", 4);
     }
   }

   if (serverErrors.includes(error?.response?.status)) {
     message.error("Произошла непредвиденная ошибка. Пожалуйста, попробуйте позднее", 4);
   }

    return Promise.reject(error);
  });

const CancelToken = axios.CancelToken;
export let cancel;

const get = (api, path, params) => {
  const token =
    typeof window !== "undefined" &&
    window.localStorage &&
    localStorage.getItem("authtoken");

  const myUuid = uuidv4();

  return axios({
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${JSON.parse(token)}`,
      "X-Api-Request-Id": myUuid,
    },
    method: "get",
    url: `${api}${path}`,
    params,
    cancelToken: new CancelToken(function executor(canceler) {
      cancel = canceler;
    })
  });
};

const put = (api, path, body) => {
  const token =
    typeof window !== "undefined" &&
    window.localStorage &&
    localStorage.getItem("authtoken");

  const myUuid = uuidv4();

  return axios({
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${JSON.parse(token)}`,
      "X-Api-Request-Id": myUuid,
    },
    method: "put",
    url: `${api}${path}`,
    data: body ? JSON.stringify(body) : undefined
  });
};

const post = (api, path, body) => {
  const token =
    typeof window !== "undefined" &&
    window.localStorage &&
    localStorage.getItem("authtoken");

  const myUuid = uuidv4();

  return axios({
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${JSON.parse(token)}`,
      "X-Api-Request-Id": myUuid,
    },
    method: "post",
    url: `${api}${path}`,
    data: body ? JSON.stringify(body) : undefined
  });
};

const patch = (api, path, body) => {
  const token =
    typeof window !== "undefined" &&
    window.localStorage &&
    localStorage.getItem("authtoken");

  const myUuid = uuidv4();

  return axios({
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${JSON.parse(token)}`,
      "X-Api-Request-Id": myUuid,
    },
    method: "patch",
    url: `${api}${path}`,
    data: body ? JSON.stringify(body) : undefined
  });
};

const deleted = (api, path, body) => {
  const token =
    typeof window !== "undefined" &&
    window.localStorage &&
    localStorage.getItem("authtoken");

  const myUuid = uuidv4();

  return axios({
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${JSON.parse(token)}`,
      "X-Api-Request-Id": myUuid,
    },
    method: "delete",
    url: `${api}${path}`,
    data: body ? JSON.stringify(body) : undefined
  });
};

const getFile = (host, path, params) => {
  const token =
    typeof window !== "undefined" &&
    window.localStorage &&
    localStorage.getItem("authtoken");
  const myUuid = uuidv4();

  return axios({
    responseType: "blob",
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${JSON.parse(token)}`,
      "X-Api-Request-Id": myUuid,
    },
    method: "get",
    url: `${host}${path}`,
    params,
  });
};

const getKeyCloak = (api, path, params) => {
  const token =
    typeof window !== "undefined" &&
    window.localStorage &&
    localStorage.getItem("authtoken");

  return axios({
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${JSON.parse(token)}`,
    },
    method: "get",
    url: `${api}${path}`,
    params,
    cancelToken: new CancelToken(function executor(canceler) {
      cancel = canceler;
    })
  });
};

const putKeyCloak = (api, path, body) => {
  const token = typeof window !== "undefined"
    && window.localStorage
    && localStorage.getItem("authtoken");

  return axios({
    headers: {
      "Content-Type": "application/json", //application/x-www-form-urlencoded
      "Authorization": `Bearer ${JSON.parse(token)}`,
    },
    method: "put",
    url: `${api}${path}`,
    data: body,
  });
};

const postKeyCloakLogin = (api, path, body) => {
  const token =
    typeof window !== "undefined" &&
    window.localStorage &&
    localStorage.getItem("authtoken");

  return axios({
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      "Authorization": `Bearer ${JSON.parse(token)}`,
    },
    method: "post",
    url: `${api}${path}`,
    data: paramsToQueryString(body),
  });
};

const postForImport = (api, path, body) => {
  const formBody = new FormData();
  formBody.append("file", body["file"]);

  const token =
    typeof window !== "undefined" &&
    window.localStorage &&
    localStorage.getItem("authtoken");

  const myUuid = uuidv4();

  return axios({
    headers: {
      "Content-Type": "multipart/form-data",
      "Authorization": `bearer ${JSON.parse(token)}`,
      "X-Api-Request-Id": myUuid,
    },
    method: "post",
    url: `${api}/${path}`,
    data: formBody,
  });
};

const formDataForContract = (method, api, path, body) => {
  const formBody = new FormData();

  formBody.append("contract", JSON.stringify(body["contract"]));
  body["files"].forEach(file => {
    if (file) formBody.append("files", file);
  });

  const token =
    typeof window !== "undefined" &&
    window.localStorage &&
    localStorage.getItem("authtoken");

  const myUuid = uuidv4();

  return axios({
    headers: {
      "Content-Type": "multipart/form-data",
      "Authorization": `bearer ${JSON.parse(token)}`,
      "X-Api-Request-Id": myUuid,
    },
    method: method,
    url: `${api}/${path}`,
    data: formBody,
  });
};

export default {
  GET: get,
  PUT: put,
  POST: post,
  POST_FOR_IMPORT: postForImport,
  POST_PUT_FOR_CONTRACT: formDataForContract,
  PATCH: patch,
  DELETE: deleted,
  POST_LOGIN: postKeyCloakLogin,
  GET_FILE: getFile,
  GET_KEYCLOACK: getKeyCloak,
  PUT_KEYCLOACK: putKeyCloak,
};
