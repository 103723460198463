import {
  ACCOUNT_RESET_PASSWORD_STATUS,
  ACCOUNT_RESET_PASSWORD_STATUS_FAILURE,
  CONFIRM_NEW_PASSWORD_STATUS,
  CONFIRM_NEW_PASSWORD_STATUS_FAILURE,
  CREATE_ROLE_FAILURE,
  CREATE_ROLE_SUCCESS,
  CREATE_USER_FAILURE,
  CREATE_USER_SUCCESS,
  DECODE_TOKEN,
  DELETE_ROLE_FAILURE,
  DELETE_ROLE_SUCCESS,
  DELETE_USER_FAILURE,
  DELETE_USER_SUCCESS,
  FETCH_USER_INFO_FAILURE,
  FETCH_USER_INFO_SUCCESS,
  GET_PORTAL_SETTINGS_FAILURE,
  GET_PORTAL_SETTINGS_SUCCESS,
  GET_ROLE_FAILURE,
  GET_ROLE_PERMS_FAILURE,
  GET_ROLE_PERMS_SUCCESS,
  GET_ROLE_SUCCESS,
  GET_ROLES_FAILURE,
  GET_ROLES_SUCCESS,
  GET_USERS_LIST_FAILURE,
  GET_USERS_LIST_SUCCESS,
  LOGIN_FAILURE,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGOUT,
  NEW_PASSWORD_CONFIRMED_STATUS,
  NEW_PASSWORD_CONFIRMED_STATUS_FAILURE,
  REFRESH_AUTH_TOKEN,
  REFRESH_AUTH_TOKEN_FAILURE,
  SAVE_PORTAL_SETTINGS_FAILURE,
  SAVE_PORTAL_SETTINGS_SUCCESS,
  TOKEN_REVOCATION_FAILURE,
  TOKEN_REVOCATION_SUCCESS,
  UPDATE_ROLE_FAILURE,
  UPDATE_ROLE_SUCCESS,
  UPDATE_USER_FAILURE,
  UPDATE_USER_SUCCESS,
} from "@/constants/actionTypes";
import {
  AccountActionsTypes,
} from "@/actions/account.actions";
import {
  RoleType,
  UserType,
  KboOrEulaType,
  RolePermsType,
  PortalSettingsDataType,
  UserInfoType,
} from "app/types";

const storage: Storage = typeof window !== "undefined" && window.localStorage;
const token: string | null = storage && storage.authtoken ? JSON.parse(storage.authtoken) : null;
const refreshToken: string | null = storage && storage.refreshToken ? JSON.parse(storage.refreshToken) : null;

export type InitialStateType = {
  isFetching: boolean;
  isFetchingRoles: boolean;
  isFetchingUsers: boolean;
  isFetchingDepartments: boolean;
  kbo: KboOrEulaType;
  eula: KboOrEulaType;
  otherDocs: Array<KboOrEulaType> | [];
  error: string | null;
  authtoken: string | null;
  refreshToken: string | null;
  decodedToken: any | null;
  resetPasswordStatus: number;
  confirmNewPasswordStatus: number;
  resetPasswordStatusCode: number;
  usersList: UserType[];
  userUpdateStatus: number;
  deleteUserStatus: number;
  newUser: UserType;
  perms: RolePermsType;
  userInfo: UserInfoType;
  roles: RoleType[];
  newRole: RoleType;
  roleInfo: RoleType;
  newPasswordConfirmedStatus: number;
  deleteRoleStatus: number;
  roleUpdateStatus: number;
  usersListPages: number;
  portalSettingsData: PortalSettingsDataType;
  savePortalSettingsStatus: number;
};

const initialState: InitialStateType = {
  isFetching: false,
  isFetchingRoles: false,
  isFetchingUsers: false,
  isFetchingDepartments: false,
  kbo: null,
  eula: null,
  error: null,
  otherDocs: [],
  authtoken: token,
  refreshToken: refreshToken,
  decodedToken: null,
  resetPasswordStatus: 0,
  confirmNewPasswordStatus: null,
  resetPasswordStatusCode: 0,
  usersList: [],
  userUpdateStatus: 0,
  deleteUserStatus: 0,
  newUser: null,
  perms: null,
  userInfo: null,
  roles: [],
  newRole: null,
  roleInfo: null,
  newPasswordConfirmedStatus: 0,
  deleteRoleStatus: 0,
  roleUpdateStatus: 0,
  usersListPages: 1,
  portalSettingsData: null,
  savePortalSettingsStatus: 0,
};

const account = (
  state = initialState,
  action: AccountActionsTypes
): InitialStateType => {
  switch (action.type) {
    // Логика расшифровки токена авторизованного пользователя
    case DECODE_TOKEN:
      return {
        ...state,
        isFetching: false,
        decodedToken: action.payload.decodedToken,
        error: null,
      };
    // Авторизация
    case LOGIN_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        isFetching: false,
        authtoken: action.payload.access_token,
        refreshToken: action.payload.refresh_token,
        error: null,
      };
    case LOGIN_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload.error,
      };
    // Refresh
    case REFRESH_AUTH_TOKEN:
      return {
        ...state,
        isFetching: false,
        authtoken: action.payload.access_token,
        refreshToken: action.payload.refresh_token,
        error: null
      };
    case REFRESH_AUTH_TOKEN_FAILURE:
      return {
        ...state,
        isFetching: false,
        authtoken: null,
        refreshToken: null,
        error: action.payload.error
      };
    // Деавторизация
    case LOGOUT:
      return {
        ...state,
        authtoken: null,
        refreshToken: null,
        userInfo: null,
      };
    // Отзыв токена
    case TOKEN_REVOCATION_SUCCESS:
      return {
        ...state,
        isFetching: false,
        authtoken: null,
        refreshToken: null,
        error: null,
      };
    case TOKEN_REVOCATION_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload.error
      };
    case ACCOUNT_RESET_PASSWORD_STATUS:
      return {
        ...state,
        resetPasswordStatus:  action.payload.status,
      }
    case ACCOUNT_RESET_PASSWORD_STATUS_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        resetPasswordStatus: 0,
      }
    case CONFIRM_NEW_PASSWORD_STATUS:
      return {
        ...state,
        confirmNewPasswordStatus: action.payload.status,
      }
    case CONFIRM_NEW_PASSWORD_STATUS_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        confirmNewPasswordStatus: 0,
      }
    case GET_USERS_LIST_SUCCESS:
      return {
        ...state,
        usersList:  action.payload.usersList.records,
        usersListPages:  action.payload.usersList.pages,
        deleteUserStatus: 0,
        userUpdateStatus: 0,
        isFetchingUsers: true,
      }
    case GET_USERS_LIST_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        usersList:  null,
        isFetchingUsers: true,
      }
    case UPDATE_USER_SUCCESS:
      return {
        ...state,
        userUpdateStatus: action.payload.userUpdateStatus,
        error: null,
      }
    case UPDATE_USER_FAILURE:
      return {
        ...state,
        error: action.payload.error,
      }
    case DELETE_USER_SUCCESS:
      return {
        ...state,
        deleteUserStatus: action.payload.deleteUserStatus,
      }
    case DELETE_USER_FAILURE:
      return {
        ...state,
        error: action.payload.error,
      }
    case CREATE_USER_SUCCESS:
      return {
        ...state,
        newUser: action.payload.newUser,
        error: null,
      }
    case CREATE_USER_FAILURE:
      return {
        ...state,
        newUser: null,
        error: action.payload.error,
      }
    case GET_ROLE_PERMS_SUCCESS:
      return {
        ...state,
        perms: action.payload.perms,
      }
    case GET_ROLE_PERMS_FAILURE:
      return {
        ...state,
        error: action.payload.error,
      }
    case GET_ROLES_SUCCESS:
      return {
        ...state,
        roles: action.payload.roles,
        deleteRoleStatus: 0,
        roleUpdateStatus: 0,
        newRole: null,
        isFetchingRoles: true,
      }
    case GET_ROLES_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        isFetchingRoles: true
      }
    case CREATE_ROLE_SUCCESS:
      return {
        ...state,
        newRole: action.payload.newRole,
        error: null,
        deleteRoleStatus: 0,
        roleUpdateStatus: 0,
      }
    case CREATE_ROLE_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        newRole: null,
        deleteRoleStatus: 0,
        roleUpdateStatus: 0,
      }
    case DELETE_ROLE_SUCCESS:
      return {
        ...state,
        deleteRoleStatus: action.payload.deleteRoleStatus,
        error: null,
      }
    case DELETE_ROLE_FAILURE:
      return {
        ...state,
        deleteRoleStatus: null,
        error: action.payload.error,
      }
    case GET_ROLE_SUCCESS:
      return {
        ...state,
        roleInfo: action.payload.roleInfo,
        error: null,
      }
    case GET_ROLE_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        roleInfo: null,
      }
    case UPDATE_ROLE_SUCCESS:
      return {
        ...state,
        roleUpdateStatus: action.payload.roleUpdateStatus,
        error: null,
      }
    case UPDATE_ROLE_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        roleUpdateStatus: 0,
      }
    case FETCH_USER_INFO_SUCCESS:
      return {
        ...state,
        userInfo: action.payload.userInfo,
        error: null,
      }
    case FETCH_USER_INFO_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        userInfo: null,
      }
    case NEW_PASSWORD_CONFIRMED_STATUS:
      return {
        ...state,
        newPasswordConfirmedStatus: action.payload.newPasswordConfirmedStatus,
      }
    case NEW_PASSWORD_CONFIRMED_STATUS_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        newPasswordConfirmedStatus: 0,
      }
    case SAVE_PORTAL_SETTINGS_SUCCESS:
      return {
        ...state,
        savePortalSettingsStatus: action.payload.savePortalSettingsStatus,
        error: null,
      }
    case SAVE_PORTAL_SETTINGS_FAILURE:
      return {
        ...state,
        savePortalSettingsStatus: 0,
        error: action.payload.error,
      }
    case GET_PORTAL_SETTINGS_SUCCESS:
      return {
        ...state,
        portalSettingsData: action.payload.portalSettings,
        error: null,
      }
    case GET_PORTAL_SETTINGS_FAILURE:
      return {
        ...state,
        portalSettingsData: null,
        error: action.payload.error,
      }
    default:
      return state;
  }
};

export default account;
