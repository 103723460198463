import {
  GET_NOTIFICATION_SUCCESS,
  GET_NOTIFICATION_FAILURE,
  READ_NOTIFICATION_SUCCESS,
  READ_NOTIFICATION_FAILURE,
} from "@/constants/actionTypes";

import { NotificationActionsTypes } from "@/actions/notifications.actions";
import { NotificationListType } from "app/types";

export type InitialStateType = {
  notificationReadStatus: number;
  notificationsPages: number;
  notifications: NotificationListType[];
  error: string;
};

const initialState: InitialStateType = {
  notificationReadStatus: 0,
  notificationsPages: 0,
  notifications: [],
  error: null,
};

const account = (
  state = initialState,
  action: NotificationActionsTypes
): InitialStateType => {
  switch (action.type) {
    case GET_NOTIFICATION_SUCCESS:
      return {
        ...state,
        notifications: action.payload.notifications.records,
        notificationsPages: action.payload.notifications.pages,
        error: null,
      };
    case GET_NOTIFICATION_FAILURE:
      return {
        ...state,
        notifications: [],
        notificationsPages: 0,
        error: action.payload.error,
      };
    case READ_NOTIFICATION_SUCCESS:
      return {
        ...state,
        notificationReadStatus: action.payload.notificationReadStatus,
        error: null,
      };
    case READ_NOTIFICATION_FAILURE:
      return {
        ...state,
        notificationReadStatus: 0,
        error: action.payload.error,
      };
    default:
      return state;
  }
};

export default account;
