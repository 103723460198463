import {
  ADD_DEPARTMENTS_LIMIT_FAILURE,
  ADD_DEPARTMENTS_LIMIT_SUCCESS,
  ADD_MAIN_LIMIT_FAILURE,
  ADD_MAIN_LIMIT_SUCCESS,
  DELETE_DEPARTMENTS_LIMIT_FAILURE,
  DELETE_DEPARTMENTS_LIMIT_SUCCESS,
  DELETE_MAIN_LIMIT_FAILURE,
  DELETE_MAIN_LIMIT_SUCCESS,
  GET_DEPARTMENTS_LIMITS_FAILURE,
  GET_DEPARTMENTS_LIMITS_STATUS_SUCCESS,
  GET_DEPARTMENTS_LIMITS_SUCCESS,
  GET_MAIN_LIMITS_FAILURE,
  GET_MAIN_LIMITS_STATUS_SUCCESS,
  GET_MAIN_LIMITS_SUCCESS,
  UPDATE_DEPARTMENTS_LIMIT_FAILURE,
  UPDATE_DEPARTMENTS_LIMIT_SUCCESS,
  UPDATE_MAIN_LIMIT_FAILURE,
  UPDATE_MAIN_LIMIT_SUCCESS,
} from "@/constants/actionTypes";
import {LimitsActionsTypes} from "@/actions/limits.actions";
import {DepartmentsLimitsType, MainLimitsType} from "app/types";

type InitialStateType = {
  mainLimits: MainLimitsType[];
  mainLimitsPages: number;
  newMainLimitId: number;
  updateMainLimitStatus: number;
  departmentsLimits: DepartmentsLimitsType[];
  departmentsLimitsPages: number;
  deleteMainLimitStatus: number;
  newDepartmentLimitId: number;
  updateDepartmentLimitStatus: number;
  deleteDepartmentsLimitStatus: number;
  getDepartmentsLimitsStatus: number;
  getMainLimitsStatus: number;
  mainLimitError: string;
  departmentLimitError: string;
  isFetching: boolean;
};

const initialState: InitialStateType = {
  mainLimits: [],
  mainLimitsPages: 1,
  newMainLimitId: 0,
  updateMainLimitStatus: 0,
  deleteMainLimitStatus: 0,
  departmentsLimits: [],
  departmentsLimitsPages: 1,
  newDepartmentLimitId: 0,
  updateDepartmentLimitStatus: 0,
  deleteDepartmentsLimitStatus: 0,
  getDepartmentsLimitsStatus: 0,
  getMainLimitsStatus: 0,
  mainLimitError: null,
  departmentLimitError: null,
  isFetching: true,
};

const limits = (
  state = initialState,
  action: LimitsActionsTypes
): InitialStateType => {
  switch (action.type) {
    case GET_MAIN_LIMITS_SUCCESS:
      return {
        ...state,
        mainLimits: action.payload.mainLimits.records,
        mainLimitsPages: action.payload.mainLimits.pages,
        mainLimitError: null,
      };
    case GET_MAIN_LIMITS_FAILURE:
      return {
        ...state,
        mainLimits: [],
        mainLimitsPages: 1,
        mainLimitError: action.payload.error,
      };
    case ADD_MAIN_LIMIT_SUCCESS:
      return {
        ...state,
        newMainLimitId: action.payload.newMainLimitId,
        mainLimitError: null,
      };
    case ADD_MAIN_LIMIT_FAILURE:
      return {
        ...state,
        newMainLimitId: 0,
        mainLimitError: action.payload.error,
      };
    case UPDATE_MAIN_LIMIT_SUCCESS:
      return {
        ...state,
        updateMainLimitStatus: action.payload.updateMainLimitStatus,
        mainLimitError: null,
      };
    case UPDATE_MAIN_LIMIT_FAILURE:
      return {
        ...state,
        updateMainLimitStatus: 0,
        mainLimitError: action.payload.error,
      };
    case DELETE_MAIN_LIMIT_SUCCESS:
      return {
        ...state,
        deleteMainLimitStatus: action.payload.deleteMainLimitStatus,
        mainLimitError: null,
      };
    case DELETE_MAIN_LIMIT_FAILURE:
      return {
        ...state,
        deleteMainLimitStatus: 0,
        mainLimitError: action.payload.error,
      };
    case GET_DEPARTMENTS_LIMITS_SUCCESS:
      return {
        ...state,
        departmentsLimits: action.payload.departmentsLimits.records,
        departmentsLimitsPages: action.payload.departmentsLimits.pages,
        departmentLimitError: null,
      };
    case GET_DEPARTMENTS_LIMITS_FAILURE:
      return {
        ...state,
        departmentsLimits: [],
        departmentsLimitsPages: 1,
        departmentLimitError: action.payload.error,
      };
    case ADD_DEPARTMENTS_LIMIT_SUCCESS:
      return {
        ...state,
        newDepartmentLimitId: action.payload.newDepartmentLimitId,
        departmentLimitError: null,
      };
    case ADD_DEPARTMENTS_LIMIT_FAILURE:
      return {
        ...state,
        newDepartmentLimitId: 0,
        departmentLimitError: action.payload.error,
      };
    case UPDATE_DEPARTMENTS_LIMIT_SUCCESS:
      return {
        ...state,
        updateDepartmentLimitStatus: action.payload.updateDepartmentLimitStatus,
        departmentLimitError: null,
      };
    case UPDATE_DEPARTMENTS_LIMIT_FAILURE:
      return {
        ...state,
        updateDepartmentLimitStatus: 0,
        departmentLimitError: action.payload.error,
      };
    case DELETE_DEPARTMENTS_LIMIT_SUCCESS:
      return {
        ...state,
        deleteDepartmentsLimitStatus: action.payload.deleteDepartmentsLimitStatus,
        departmentLimitError: null,
      };
    case DELETE_DEPARTMENTS_LIMIT_FAILURE:
      return {
        ...state,
        deleteDepartmentsLimitStatus: 0,
        departmentLimitError: action.payload.error,
      };
    case GET_DEPARTMENTS_LIMITS_STATUS_SUCCESS:
      return {
        ...state,
        getDepartmentsLimitsStatus: action.payload.getDepartmentsLimitsStatus,
        departmentLimitError: null,
      };
    case GET_MAIN_LIMITS_STATUS_SUCCESS:
      return {
        ...state,
        getMainLimitsStatus: action.payload.getMainLimitsStatus,
        mainLimitError: null,
      };
    default:
      return state;
  }
};

export default limits;
