import {
  CURRENT_ORDER_REQUEST,
  CURRENT_ORDER_SUCCESS,
  CURRENT_ORDER_FAILURE,
  CURRENT_ORDER_DOCS_REQUEST,
  CURRENT_ORDER_DOCS_SUCCESS,
  CURRENT_ORDER_DOCS_FAILURE,
  CURRENT_ORDER_OPTIONS_REQUEST,
  CURRENT_ORDER_OPTIONS_SUCCESS,
  CURRENT_ORDER_OPTIONS_FAILURE,
  CURRENT_ORDER_UPDATE_ENTITY,
  CANCEL_ORDER_REQUEST,
  CANCEL_ORDER_SUCCESS,
  CANCEL_ORDER_FAILURE,
} from "@/constants/actionTypes";
import { CurrentOrderActionsTypes } from "@/actions/currentOrder.actions";
import {
  CurrentOrderType,
  CurrentOrderDocType,
  CurrentOrderCancelOptionType,
} from "app/types";

export type InitialStateType = {
  isFetchingOrder: boolean;
  orderData: CurrentOrderType;
  isFetchingDocs: boolean;
  orderDocs: CurrentOrderDocType[];
  isDocsRequestFailed: boolean;
  orderOptions: CurrentOrderCancelOptionType[];
  isFetchingOptions: boolean;
  isCanceled: boolean;
  isCancelRequestFailed: boolean;
};

const initialState: InitialStateType = {
  isFetchingOrder: true,
  orderData: null,
  isFetchingDocs: true,
  orderDocs: [],
  isDocsRequestFailed: false,
  orderOptions: [],
  isFetchingOptions: false,
  isCanceled: false,
  isCancelRequestFailed: false,
};

const currentOrder = (
  state = initialState,
  action: CurrentOrderActionsTypes
): InitialStateType => {
  switch (action.type) {
    case CURRENT_ORDER_REQUEST:
      return { ...state, isFetchingOrder: true };
    case CURRENT_ORDER_SUCCESS:
      return {
        ...state,
        isFetchingOrder: false,
        orderData: action.orderData,
      };
    case CURRENT_ORDER_FAILURE:
      return { ...state, isFetchingOrder: false };
    case CURRENT_ORDER_DOCS_REQUEST:
      return { ...state, isFetchingDocs: true, isDocsRequestFailed: false };
    case CURRENT_ORDER_DOCS_SUCCESS:
      return { ...state, isFetchingDocs: false, orderDocs: action.orderDocs };
    case CURRENT_ORDER_DOCS_FAILURE:
      return { ...state, isFetchingDocs: false, isDocsRequestFailed: true };
    case CURRENT_ORDER_OPTIONS_REQUEST:
      return { ...state, isFetchingOptions: true };
    case CURRENT_ORDER_OPTIONS_SUCCESS:
      return {
        ...state,
        isFetchingOptions: false,
        orderOptions: action.orderOptions,
      };
    case CURRENT_ORDER_OPTIONS_FAILURE:
      return { ...state, isFetchingOptions: false };
    case CURRENT_ORDER_UPDATE_ENTITY:
      const {
        payload: { entity, value },
      } = action;
      return { ...state, [entity]: value };
    case CANCEL_ORDER_REQUEST:
      return { ...state, isFetchingOrder: true };
    case CANCEL_ORDER_SUCCESS:
      return { ...state, isFetchingOrder: false, isCanceled: true };
    case CANCEL_ORDER_FAILURE:
      return { ...state, isFetchingOrder: false, isCancelRequestFailed: true };
    default:
      return state;
  }
};

export default currentOrder;
