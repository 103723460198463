import {
  GET_USER_CART_SUCCESS,
  GET_USER_CART_FAILURE,
  ADD_PRODUCT_IN_CART_SUCCESS,
  ADD_PRODUCT_IN_CART_FAILURE,
  REMOVE_PRODUCT_FROM_CART_SUCCESS,
  REMOVE_PRODUCT_FROM_CART_FAILURE,
  UPDATE_PRODUCT_IN_CART_SUCCESS,
  UPDATE_PRODUCT_IN_CART_FAILURE,
} from "@/constants/actionTypes";
import { CartActionsTypes } from "@/actions/cart.actions";
import { UserCartType } from "app/types";

type InitialStateType = {
  userCart: UserCartType;
  error: string;
  addProductStatus: number;
  removeProductStatus: number;
  updateProductStatus: number;
};

const initialState: InitialStateType = {
  userCart: null,
  error: null,
  addProductStatus: 0,
  removeProductStatus: 0,
  updateProductStatus: 0,
};

const cart = (
  state = initialState,
  action: CartActionsTypes
): InitialStateType => {
  switch (action.type) {
    case GET_USER_CART_SUCCESS:
      return {
        ...state,
        userCart: action.payload.userCart,
        error: null,
      };
    case GET_USER_CART_FAILURE:
      return {
        ...state,
        userCart: null,
        error: action.payload.error,
      };
    case ADD_PRODUCT_IN_CART_SUCCESS:
      return {
        ...state,
        addProductStatus: action.payload.addProductStatus,
        error: null,
      };
    case ADD_PRODUCT_IN_CART_FAILURE:
      return {
        ...state,
        addProductStatus: 0,
        error: action.payload.error,
      };
    case REMOVE_PRODUCT_FROM_CART_SUCCESS:
      return {
        ...state,
        removeProductStatus: action.payload.removeProductStatus,
        error: null,
      };
    case REMOVE_PRODUCT_FROM_CART_FAILURE:
      return {
        ...state,
        removeProductStatus: 0,
        error: action.payload.error,
      };
    case UPDATE_PRODUCT_IN_CART_SUCCESS:
      return {
        ...state,
        updateProductStatus: action.payload.updateProductStatus,
        error: null,
      };
    case UPDATE_PRODUCT_IN_CART_FAILURE:
      return {
        ...state,
        updateProductStatus: 0,
        error: action.payload.error,
      };
    default:
      return state;
  }
};

export default cart;
