import React from "react";
import PropTypes from "prop-types";
import { StateProvider } from "@/store";
import useAOS from "@/hooks/useAOS";
import DevTools from "@/devTools";
import 'antd/dist/reset.css';
import "@/assets/scss/global.scss";

const Layout = ({ children }) => {
  useAOS();

  return (
    <StateProvider>
      <DevTools />
      {children}
    </StateProvider>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
