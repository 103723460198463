import React from "react";
import Grid from "./grid";
import config from "./devTools.config";

const DevTools = () => {
  return (
    <>
      <Grid {...config.grid} />
    </>
  );
};

export default DevTools;
