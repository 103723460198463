export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";

export const DECODE_TOKEN = "DECODE_TOKEN";

export const TOKEN_REVOCATION_SUCCESS = "TOKEN_REVOCATION_SUCCESS";
export const TOKEN_REVOCATION_FAILURE = "TOKEN_REVOCATION_FAILURE";

export const LOGOUT = "LOGOUT";

export const REFRESH_AUTH_TOKEN = "REFRESH_AUTH_TOKEN";
export const REFRESH_AUTH_TOKEN_FAILURE = "REFRESH_AUTH_TOKEN_FAILURE";

export const FETCH_USER_INFO_SUCCESS = "FETCH_USER_INFO_SUCCESS";
export const FETCH_USER_INFO_FAILURE = "FETCH_USER_INFO_FAILURE";

export const SERVICES_REQUEST = "SERVICES_REQUEST";
export const SERVICES_SUCCESS = "SERVICES_SUCCESS";
export const SERVICES_FAILURE = "SERVICES_FAILURE";

export const SEARCH_GET = "SEARCH_GET";
export const SEARCH_GET_SUCCESS = "SEARCH_GET_SUCCESS";
export const SEARCH_GET_FAILURE = "SEARCH_GET_FAILURE";

export const SEARCH_PAGINATION_UPDATE = "SEARCH_PAGINATION_UPDATE";

export const SEARCH_CATALOG_GET = "SEARCH_CATALOG_GET";
export const SEARCH_CATALOG_GET_SUCCESS = "SEARCH_CATALOG_GET_SUCCESS";
export const SEARCH_CATALOG_GET_FAILURE = "SEARCH_CATALOG_GET_FAILURE";

export const SUPPLIERS_SUCCESS = "SUPPLIERS_SUCCESS";
export const SUPPLIERS_FAILURE = "SUPPLIERS_FAILURE";
export const CREATE_SUPPLIER_SUCCESS = "CREATE_SUPPLIER_SUCCESS";
export const CREATE_SUPPLIER_FAILURE = "CREATE_SUPPLIER_FAILURE";
export const CREATE_SUPPLIER_STATUS_SUCCESS = "CREATE_SUPPLIER_STATUS_SUCCESS";
export const CREATE_SUPPLIER_STATUS_FAILURE = "CREATE_SUPPLIER_STATUS_FAILURE";
export const GET_SUPPLIER_SUCCESS = "GET_SUPPLIER_SUCCESS";
export const GET_SUPPLIER_FAILURE = "GET_SUPPLIER_FAILURE";
export const UPDATE_CURRENT_SUPPLIER_SUCCESS = "UPDATE_CURRENT_SUPPLIER_SUCCESS";
export const UPDATE_SUPPLIER_SUCCESS = "UPDATE_SUPPLIER_SUCCESS";
export const UPDATE_SUPPLIER_FAILURE = "UPDATE_SUPPLIER_FAILURE";
export const DELETE_SUPPLIER_SUCCESS = "DELETE_SUPPLIER_SUCCESS";
export const DELETE_SUPPLIER_FAILURE = "DELETE_SUPPLIER_FAILURE";

export const NOTIFICATIONS_REQUEST = "NOTIFICATIONS_REQUEST";
export const NOTIFICATIONS_SUCCESS = "NOTIFICATIONS_SUCCESS";
export const NOTIFICATIONS_FAILURE = "NOTIFICATIONS_FAILURE";

export const SEARCH_UPDATE_ENTITY_VALUE = "SEARCH_UPDATE_ENTITY_VALUE";

export const ACCOUNT_ADD_COMPANY_USER_SUCCESS =
  "ACCOUNT_ADD_COMPANY_USER_SUCCESS";

export const GET_NOTIFICATION_SUCCESS =
  "GET_NOTIFICATION_SUCCESS";
export const GET_NOTIFICATION_FAILURE =
  "GET_NOTIFICATION_FAILURE";
export const READ_NOTIFICATION_SUCCESS =
  "READ_NOTIFICATION_SUCCESS";
export const READ_NOTIFICATION_FAILURE =
  "READ_NOTIFICATION_FAILURE";


export const SEARCH_GET_SECTIONS_REQUEST = "SEARCH_GET_SECTIONS_REQUEST";
export const SEARCH_GET_SECTIONS_SUCCESS = "SEARCH_GET_SECTIONS_SUCCESS";
export const SEARCH_GET_SECTIONS_FAILURE = "SEARCH_GET_SECTIONS_FAILURE";

export const SEARCH_GET_FAQ_REQUEST = "SEARCH_GET_FAQ_REQUEST";
export const SEARCH_GET_FAQ_SUCCESS = "SEARCH_GET_FAQ_SUCCESS";
export const SEARCH_GET_FAQ_FAILURE = "SEARCH_GET_FAQ_FAILURE";

export const SEARCH_GET_RESULT_REQUEST = "SEARCH_GET_RESULT_REQUEST";
export const SEARCH_GET_RESULT_SUCCESS = "SEARCH_GET_RESULT_SUCCESS";
export const SEARCH_GET_RESULT_FAILURE = "SEARCH_GET_RESULT_FAILURE";

export const SEARCH_PAGINATION_REQUEST = "SEARCH_PAGINATION_REQUEST";
export const SEARCH_PAGINATION_SUCCESS = "SEARCH_PAGINATION_SUCCESS";
export const SEARCH_PAGINATION_FAILURE = "SEARCH_PAGINATION_FAILURE";

export const CURRENT_ORDER_REQUEST = "CURRENT_ORDER_REQUEST";
export const CURRENT_ORDER_SUCCESS = "CURRENT_ORDER_SUCCESS";
export const CURRENT_ORDER_FAILURE = "CURRENT_ORDER_FAILURE";

export const CURRENT_ORDER_OPTIONS_REQUEST =
  "CURRENT_ORDER_CANCEL_OPTIONS_REQUEST";
export const CURRENT_ORDER_OPTIONS_SUCCESS =
  "CURRENT_ORDER_CANCEL_OPTIONS_SUCCESS";
export const CURRENT_ORDER_OPTIONS_FAILURE =
  "CURRENT_ORDER_CANCEL_OPTIONS_FAILURE";
export const CURRENT_ORDER_UPDATE_ENTITY = "CURRENT_ORDER_UPDATE_ENTITY";
export const CANCEL_ORDER_REQUEST = "CANCEL_ORDER_REQUEST";
export const CANCEL_ORDER_SUCCESS = "CANCEL_ORDER_SUCCESS";
export const CANCEL_ORDER_FAILURE = "CANCEL_ORDER_FAILURE";

export const CURRENT_ORDER_DOCS_REQUEST = "CURRENT_ORDER_DOCS_REQUEST";
export const CURRENT_ORDER_DOCS_SUCCESS = "CURRENT_ORDER_DOCS_SUCCESS";
export const CURRENT_ORDER_DOCS_FAILURE = "CURRENT_ORDER_DOCS_FAILURE";

export const CREATE_ORDER_DOCUMENT_REQUEST = "CREATE_ORDER_DOCUMENT_REQUEST";
export const CREATE_ORDER_DOCUMENT_SUCCESS = "CREATE_ORDER_DOCUMENT_SUCCESS";
export const CREATE_ORDER_DOCUMENT_FAILURE = "CREATE_ORDER_DOCUMENT_FAILURE";

export const GET_DOCUMENT_REQUEST = "GET_DOCUMENT_REQUEST";
export const GET_DOCUMENT_SUCCESS = "GET_DOCUMENT_SUCCESS";
export const GET_DOCUMENT_FAILURE = "GET_DOCUMENT_FAILURE";

export const GET_ORDER_DOCUMENTS_REQUEST = "GET_ORDER_DOCUMENTS_REQUEST";
export const GET_ORDER_DOCUMENTS_SUCCESS = "GET_ORDER_DOCUMENTS_SUCCESS";
export const GET_ORDER_DOCUMENTS_FAILURE = "GET_ORDER_DOCUMENTS_FAILURE";

export const DELETE_ORDER_DOCUMENT_REQUEST = "DELETE_ORDER_DOCUMENT_REQUEST";
export const DELETE_ORDER_DOCUMENT_SUCCESS = "DELETE_ORDER_DOCUMENT_SUCCESS";
export const DELETE_ORDER_DOCUMENT_FAILURE = "DELETE_ORDER_DOCUMENT_FAILURE";

export const ACCOUNT_RESET_PASSWORD_STATUS =
  "ACCOUNT_RESET_PASSWORD_STATUS";
export const ACCOUNT_RESET_PASSWORD_STATUS_FAILURE =
  "ACCOUNT_RESET_PASSWORD_STATUS_FAILURE";
export const CONFIRM_NEW_PASSWORD_STATUS =
  "CONFIRM_NEW_PASSWORD_STATUS";
export const CONFIRM_NEW_PASSWORD_STATUS_FAILURE =
  "CONFIRM_NEW_PASSWORD_STATUS_FAILURE";
export const NEW_PASSWORD_CONFIRMED_STATUS =
  "NEW_PASSWORD_CONFIRMED_STATUS";
export const NEW_PASSWORD_CONFIRMED_STATUS_FAILURE =
  "NEW_PASSWORD_CONFIRMED_STATUS_FAILURE";
export const GET_USERS_LIST_SUCCESS =
  "GET_USERS_LIST_SUCCESS";
export const GET_USERS_LIST_FAILURE =
  "GET_USERS_LIST_FAILURE";
export const UPDATE_USER_SUCCESS =
  "UPDATE_USER_SUCCESS";
export const UPDATE_USER_FAILURE =
  "UPDATE_USER_FAILURE";
export const DELETE_USER_SUCCESS =
  "DELETE_USER_SUCCESS";
export const DELETE_USER_FAILURE =
  "DELETE_USER_FAILURE";
export const CREATE_USER_SUCCESS =
  "CREATE_USER_SUCCESS";
export const CREATE_USER_FAILURE =
  "CREATE_USER_FAILURE";
export const GET_ROLE_PERMS_SUCCESS =
  "GET_ROLE_PERMS_SUCCESS";
export const GET_ROLE_PERMS_FAILURE =
  "GET_ROLE_PERMS_FAILURE";
export const GET_ROLES_SUCCESS =
  "GET_ROLES_SUCCESS";
export const GET_ROLES_FAILURE =
  "GET_ROLES_FAILURE";
export const CREATE_ROLE_SUCCESS =
  "CREATE_ROLE_SUCCESS";
export const CREATE_ROLE_FAILURE =
  "CREATE_ROLE_FAILURE";
export const DELETE_ROLE_SUCCESS =
  "DELETE_ROLE_SUCCESS";
export const DELETE_ROLE_FAILURE =
  "DELETE_ROLE_FAILURE";
export const GET_ROLE_SUCCESS =
  "GET_ROLE_SUCCESS";
export const GET_ROLE_FAILURE =
  "GET_ROLE_FAILURE";
export const UPDATE_ROLE_SUCCESS =
  "UPDATE_ROLE_SUCCESS";
export const UPDATE_ROLE_FAILURE =
  "UPDATE_ROLE_FAILURE";

export const GET_TP_CATEGORIES_SUCCESS =
  "GET_TP_CATEGORIES_SUCCESS";
export const GET_TP_CATEGORIES_FAILURE =
  "GET_TP_CATEGORIES_FAILURE";
export const GET_TP_CURRENT_CATEGORY =
  "GET_TP_CURRENT_CATEGORY";

export const GET_REGIONS_SUCCESS =
  "GET_REGIONS_SUCCESS";
export const GET_REGIONS_FAILURE =
  "GET_REGIONS_FAILURE";

export const GET_LOGS_EVENTS_SUCCESS =
  "GET_LOGS_EVENTS_SUCCESS";
export const GET_LOGS_EVENTS_FAILURE =
  "GET_LOGS_EVENTS_FAILURE";
export const GET_EVENTS_TYPES_SUCCESS =
  "GET_EVENTS_TYPES_SUCCESS";
export const GET_EVENTS_TYPES_FAILURE =
  "GET_EVENTS_TYPES_FAILURE";

export const GET_OKATO_SUCCESS =
  "GET_OKATO_SUCCESS";
export const GET_OKATO_FAILURE =
  "GET_OKATO_FAILURE";

export const GET_PRODUCTS_REQUEST =
    "GET_PRODUCTS_REQUEST";
export const GET_PRODUCTS_SUCCESS =
    "GET_PRODUCTS_SUCCESS";
export const GET_PRODUCTS_FAILURE =
    "GET_PRODUCTS_FAILURE";
export const GET_PRODUCTS_CATALOG_SUCCESS =
  "GET_PRODUCTS_CATALOG_SUCCESS";
export const GET_PRODUCTS_CATALOG_FAILURE =
  "GET_PRODUCTS_CATALOG_FAILURE";
export const GET_PRODUCT_STATUS_SUCCESS =
  "GET_PRODUCT_STATUS_SUCCESS";
export const GET_PRODUCT_STATUS_FAILURE =
  "GET_PRODUCT_STATUS_FAILURE";
export const GET_PRODUCT_SUCCESS =
    "GET_PRODUCT_SUCCESS";
export const GET_PRODUCT_FAILURE =
    "GET_PRODUCT_FAILURE";
export const UPDATE_PRODUCT_SUCCESS =
    "UPDATE_PRODUCT_SUCCESS";
export const UPDATE_PRODUCT_FAILURE =
    "UPDATE_PRODUCT_FAILURE";
export const DELETE_PRODUCT_SUCCESS =
    "DELETE_PRODUCT_SUCCESS";
export const DELETE_PRODUCT_FAILURE =
    "DELETE_PRODUCT_FAILURE";

export const IMPORT_CATEGORIES_SUCCESS =
    "IMPORT_CATEGORIES_SUCCESS";
export const IMPORT_CATEGORIES_FAILURE =
    "IMPORT_CATEGORIES_FAILURE";
export const IMPORT_REGIONS_SUCCESS =
    "IMPORT_REGIONS_SUCCESS";
export const IMPORT_REGIONS_FAILURE =
    "IMPORT_REGIONS_FAILURE";
export const IMPORT_OKATO_SUCCESS =
    "IMPORT_OKATO_SUCCESS";
export const IMPORT_OKATO_FAILURE =
    "IMPORT_OKATO_FAILURE";

export const GET_USER_CART_SUCCESS =
  "GET_USER_CART_SUCCESS";
export const GET_USER_CART_FAILURE =
  "GET_USER_CART_FAILURE";
export const ADD_PRODUCT_IN_CART_SUCCESS =
  "ADD_PRODUCT_IN_CART_SUCCESS";
export const ADD_PRODUCT_IN_CART_FAILURE =
  "ADD_PRODUCT_IN_CART_FAILURE";
export const REMOVE_PRODUCT_FROM_CART_SUCCESS =
  "REMOVE_PRODUCT_FROM_CART_SUCCESS";
export const REMOVE_PRODUCT_FROM_CART_FAILURE =
  "REMOVE_PRODUCT_FROM_CART_FAILURE";
export const UPDATE_PRODUCT_IN_CART_SUCCESS =
  "UPDATE_PRODUCT_IN_CART_SUCCESS";
export const UPDATE_PRODUCT_IN_CART_FAILURE =
  "UPDATE_PRODUCT_IN_CART_FAILURE"

export const CREATE_ORDER_SUCCESS =
  "CREATE_ORDER_SUCCESS";
export const CREATE_ORDER_FAILURE =
  "CREATE_ORDER_FAILURE";
export const GET_ORDERS_LIST_REQUEST =
  "GET_ORDERS_LIST_REQUEST";
export const GET_ORDERS_LIST_SUCCESS =
  "GET_ORDERS_LIST_SUCCESS";
export const GET_ORDERS_LIST_FAILURE =
  "GET_ORDERS_LIST_FAILURE";
export const GET_ORDER_STATUS_SUCCESS =
  "GET_ORDER_STATUS_SUCCESS";
export const GET_ORDER_STATUS_FAILURE =
  "GET_ORDER_STATUS_FAILURE";
export const GET_ORDER_SUCCESS =
  "GET_ORDER_SUCCESS";
export const GET_ORDER_FAILURE =
  "GET_ORDER_FAILURE";
export const UPDATE_ORDER_ENTITY_SUCCESS =
  "UPDATE_ORDER_ENTITY_SUCCESS";
export const UPDATE_ORDER_ENTITY_FAILURE =
  "UPDATE_ORDER_ENTITY_FAILURE";
export const EDIT_ORDER_SUCCESS =
  "EDIT_ORDER_SUCCESS";
export const EDIT_ORDER_FAILURE =
  "EDIT_ORDER_FAILURE";
export const EDIT_ORDER_POSITION_SUCCESS =
  "EDIT_ORDER_POSITION_SUCCESS";
export const EDIT_ORDER_POSITION_FAILURE =
  "EDIT_ORDER_POSITION_FAILURE";
export const DELETE_ORDER_POSITION_SUCCESS =
  "DELETE_ORDER_POSITION_SUCCESS";
export const DELETE_ORDER_POSITION_FAILURE =
  "DELETE_ORDER_POSITION_FAILURE";
export const APPROVE_ORDER_STATUS_SUCCESS =
  "APPROVE_ORDER_STATUS_SUCCESS";
export const APPROVE_ORDER_STATUS_FAILURE =
  "APPROVE_ORDER_STATUS_FAILURE";
export const UPDATE_ORDER_STATUS_SUCCESS =
  "UPDATE_ORDER_STATUS_SUCCESS";
export const UPDATE_ORDER_STATUS_FAILURE =
  "UPDATE_ORDER_STATUS_FAILURE";
export const GET_ORDER_ADDRESSES_SUCCESS =
  "GET_ORDER_ADDRESSES_SUCCESS";
export const GET_ORDER_ADDRESSES_FAILURE =
  "GET_ORDER_ADDRESSES_FAILURE";
export const CREATE_DOCUMENT = "CREATE_DOCUMENT";

export const GET_MAIN_LIMITS_SUCCESS =
  "GET_MAIN_LIMITS_SUCCESS";
export const GET_MAIN_LIMITS_FAILURE =
  "GET_MAIN_LIMITS_FAILURE";
export const ADD_MAIN_LIMIT_SUCCESS =
  "ADD_MAIN_LIMIT_SUCCESS";
export const ADD_MAIN_LIMIT_FAILURE =
  "ADD_MAIN_LIMIT_FAILURE";
export const UPDATE_MAIN_LIMIT_SUCCESS =
  "UPDATE_MAIN_LIMIT_SUCCESS";
export const UPDATE_MAIN_LIMIT_FAILURE =
  "UPDATE_MAIN_LIMIT_FAILURE"
export const DELETE_MAIN_LIMIT_SUCCESS =
  "DELETE_MAIN_LIMIT_SUCCESS";
export const DELETE_MAIN_LIMIT_FAILURE =
  "DELETE_MAIN_LIMIT_FAILURE";
export const GET_MAIN_LIMITS_STATUS_SUCCESS =
  "GET_MAIN_LIMITS_STATUS_SUCCESS";

export const GET_DEPARTMENTS_LIMITS_SUCCESS =
  "GET_DEPARTMENTS_LIMITS_SUCCESS";
export const GET_DEPARTMENTS_LIMITS_FAILURE =
  "GET_DEPARTMENTS_LIMITS_FAILURE";
export const ADD_DEPARTMENTS_LIMIT_SUCCESS =
  "ADD_DEPARTMENTS_LIMIT_SUCCESS";
export const ADD_DEPARTMENTS_LIMIT_FAILURE =
  "ADD_DEPARTMENTS_LIMIT_FAILURE";
export const UPDATE_DEPARTMENTS_LIMIT_SUCCESS =
  "UPDATE_DEPARTMENTS_LIMIT_SUCCESS";
export const UPDATE_DEPARTMENTS_LIMIT_FAILURE =
  "UPDATE_DEPARTMENTS_LIMIT_FAILURE";
export const DELETE_DEPARTMENTS_LIMIT_SUCCESS =
  "DELETE_DEPARTMENTS_LIMIT_SUCCESS";
export const DELETE_DEPARTMENTS_LIMIT_FAILURE =
  "DELETE_DEPARTMENTS_LIMIT_FAILURE";
export const GET_DEPARTMENTS_LIMITS_STATUS_SUCCESS =
  "GET_DEPARTMENTS_LIMITS_STATUS_SUCCESS";

export const SAVE_PORTAL_SETTINGS_SUCCESS =
  "SAVE_PORTAL_SETTINGS_SUCCESS";
export const SAVE_PORTAL_SETTINGS_FAILURE =
  "SAVE_PORTAL_SETTINGS_FAILURE";
export const GET_PORTAL_SETTINGS_STATUS_SUCCESS =
  "GET_PORTAL_SETTINGS_STATUS_SUCCESS";
export const GET_PORTAL_SETTINGS_SUCCESS =
  "GET_PORTAL_SETTINGS_SUCCESS";
export const GET_PORTAL_SETTINGS_FAILURE =
  "GET_PORTAL_SETTINGS_FAILURE";
export const SAVE_SUPPLIER_SERVICE_SETTINGS_SUCCESS =
  "SAVE_SUPPLIER_SERVICE_SETTINGS_SUCCESS";
export const SAVE_SUPPLIER_SERVICE_SETTINGS_FAILURE =
  "SAVE_SUPPLIER_SERVICE_SETTINGS_FAILURE";
export const GET_SUPPLIER_SERVICE_SETTINGS_SUCCESS =
  "GET_SUPPLIER_SERVICE_SETTINGS_SUCCESS";
export const GET_SUPPLIER_SERVICE_SETTINGS_FAILURE =
  "GET_SUPPLIER_SERVICE_SETTINGS_FAILURE";
export const SAVE_ORDERS_SERVICE_SETTINGS_SUCCESS =
  "SAVE_ORDERS_SERVICE_SETTINGS_SUCCESS";
export const SAVE_ORDERS_SERVICE_SETTINGS_FAILURE =
  "SAVE_ORDERS_SERVICE_SETTINGS_FAILURE";
export const GET_ORDERS_SERVICE_SETTINGS_SUCCESS =
  "GET_ORDERS_SERVICE_SETTINGS_SUCCESS";
export const GET_ORDERS_SERVICE_SETTINGS_FAILURE =
  "GET_ORDERS_SERVICE_SETTINGS_FAILURE";

export const GET_MESSAGES_LIST_SUCCESS =
  "GET_MESSAGES_LIST_SUCCESS";
export const GET_MESSAGES_LIST_FAILURE =
  "GET_MESSAGES_LIST_FAILURE";
export const GET_MESSAGE_SUCCESS =
  "GET_MESSAGE_SUCCESS";
export const GET_MESSAGE_FAILURE =
  "GET_MESSAGE_FAILURE";
export const GET_MESSAGES_SERVICE_SETTINGS_SUCCESS =
  "GET_MESSAGES_SERVICE_SETTINGS_SUCCESS";
export const GET_MESSAGES_SERVICE_SETTINGS_FAILURE =
  "GET_MESSAGES_SERVICE_SETTINGS_FAILURE";
export const SAVE_MESSAGES_SERVICE_SETTINGS_SUCCESS =
  "SAVE_MESSAGES_SERVICE_SETTINGS_SUCCESS";
export const SAVE_MESSAGES_SERVICE_SETTINGS_FAILURE =
  "SAVE_MESSAGES_SERVICE_SETTINGS_FAILURE";
