import { combineReducers } from "redux";
import account from "@/reducers/account.reducer";
import services from "@/reducers/services.reducer";
import orders from "@/reducers/orders.reducer";
import products from "@/reducers/products.reducer";
import search from "@/reducers/search.reducer";
import categories from "@/reducers/categories.reducer";
// import dashboard from "@/reducers/dashboard.reducer";
import suppliers from "@/reducers/suppliers.reducer";
import notifications from "@/reducers/notifications.reducer";
import currentOrder from "@/reducers/currentOrder.reducer";
import regions from "@/reducers/regions.reducer";
import logs from "@/reducers/logs.reducer";
import okato from "@/reducers/okato.reducer";
import cart from "@/reducers/cart.reducer";
import limits from "@/reducers/limits.reducer";
import messages from "@/reducers/messages.reducer";
import * as Sentry from "@sentry/react";
import { getEnvironment } from "@/utils/getEnvironment";

Sentry.init({
  dsn: "https://f83bef8c25b847f19a102c23659e5ba2@sentry.vtbc.net/25",
  environment: getEnvironment(),
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.browserProfilingIntegration(),
  ],
  tracesSampleRate: 1.0,
});

const mainReducer = combineReducers({
  account,
  services,
  orders,
  products,
  search,
  categories,
  // dashboard,
  suppliers,
  notifications,
  currentOrder,
  regions,
  logs,
  okato,
  cart,
  limits,
  messages,
});

type MainReducerType = typeof mainReducer;
export type AppStateType = ReturnType<MainReducerType>;

export default mainReducer;
