import {
  APPROVE_ORDER_STATUS_FAILURE,
  APPROVE_ORDER_STATUS_SUCCESS,
  CREATE_ORDER_FAILURE,
  CREATE_ORDER_SUCCESS,
  DELETE_ORDER_POSITION_FAILURE,
  DELETE_ORDER_POSITION_SUCCESS,
  EDIT_ORDER_FAILURE,
  EDIT_ORDER_POSITION_FAILURE,
  EDIT_ORDER_POSITION_SUCCESS,
  EDIT_ORDER_SUCCESS,
  GET_ORDER_ADDRESSES_FAILURE,
  GET_ORDER_ADDRESSES_SUCCESS,
  GET_ORDER_FAILURE,
  GET_ORDER_STATUS_FAILURE,
  GET_ORDER_STATUS_SUCCESS,
  GET_ORDER_SUCCESS,
  GET_ORDERS_LIST_FAILURE,
  GET_ORDERS_LIST_REQUEST,
  GET_ORDERS_LIST_SUCCESS,
  GET_ORDERS_SERVICE_SETTINGS_FAILURE,
  GET_ORDERS_SERVICE_SETTINGS_SUCCESS,
  SAVE_ORDERS_SERVICE_SETTINGS_FAILURE,
  SAVE_ORDERS_SERVICE_SETTINGS_SUCCESS,
  UPDATE_ORDER_ENTITY_FAILURE,
  UPDATE_ORDER_ENTITY_SUCCESS,
  UPDATE_ORDER_STATUS_FAILURE,
  UPDATE_ORDER_STATUS_SUCCESS,
} from "@/constants/actionTypes";
import { OrdersActionsTypes } from "@/actions/orders.actions";
import { FacetsType, OrdersServiceSettingsDataType, OrderType } from "app/types";

export type InitialStateType = {
  error: string | null;
  ordersData: OrderType[];
  orderIds: number[];
  order: OrderType;
  editOrderStatus: number;
  updatedOrderStatus: number;
  editOrderPositionStatus: number;
  deleteOrderPositionStatus: number;
  ordersDataPages: number;
  orderAddresses: string[];
  isFetching: boolean;
  facet: FacetsType[];
  count: number;
  orderServiceSettingsData: OrdersServiceSettingsDataType;
  saveOrderServiceSettingsStatus: number;
  getOrderStatus: number;
};

const initialState: InitialStateType = {
  error: null,
  ordersData: [],
  orderIds: null,
  order: null,
  editOrderStatus: 0,
  updatedOrderStatus: 0,
  editOrderPositionStatus: 0,
  deleteOrderPositionStatus: 0,
  ordersDataPages: 0,
  orderAddresses: [],
  isFetching: false,
  facet: [],
  count: 0,
  orderServiceSettingsData: null,
  saveOrderServiceSettingsStatus: 0,
  getOrderStatus: 0,
};

const updateOrderStatus = (order: OrderType, status: number): OrderType => {
  return ({
    ...order,
    status: status.toString()
  });
};

const orders = (
  state = initialState,
  action: OrdersActionsTypes
): InitialStateType => {
  switch (action.type) {
    case CREATE_ORDER_SUCCESS:
      return {
        ...state,
        orderIds: action.payload.orderIds,
        error: null,
      };
    case CREATE_ORDER_FAILURE:
      return {
        ...state,
        orderIds: null,
        error: action.payload.error,
      };
    case GET_ORDERS_LIST_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case GET_ORDERS_LIST_SUCCESS:
      return {
        ...state,
        ordersData: action.payload.orders.records,
        ordersDataPages: action.payload.orders.pages,
        error: null,
        isFetching: false,
      };
    case GET_ORDERS_LIST_FAILURE:
      return {
        ...state,
        ordersData: [],
        ordersDataPages: 0,
        error: action.payload.error,
        isFetching: false,
      };
    case GET_ORDER_SUCCESS:
      return {
        ...state,
        order: action.payload.order,
        error: null,
      };
    case GET_ORDER_FAILURE:
      return {
        ...state,
        order: null,
        getOrderStatus: 0,
        error: action.payload.error,
      };
    case EDIT_ORDER_SUCCESS:
      return {
        ...state,
        editOrderStatus: action.payload.editOrderStatus,
        error: null,
      };
    case EDIT_ORDER_FAILURE:
      return {
        ...state,
        editOrderStatus: 0,
        error: action.payload.error,
      };
    case EDIT_ORDER_POSITION_SUCCESS:
      return {
        ...state,
        editOrderPositionStatus: action.payload.editOrderPositionStatus,
        error: null,
      };
    case EDIT_ORDER_POSITION_FAILURE:
      return {
        ...state,
        editOrderPositionStatus: 0,
        error: action.payload.error,
      };
    case DELETE_ORDER_POSITION_SUCCESS:
      return {
        ...state,
        deleteOrderPositionStatus: action.payload.deleteOrderPositionStatus,
        error: null,
      };
    case DELETE_ORDER_POSITION_FAILURE:
      return {
        ...state,
        deleteOrderPositionStatus: 0,
        error: action.payload.error,
      };
    case APPROVE_ORDER_STATUS_SUCCESS:
      return {
        ...state,
        order: updateOrderStatus(state.order, action.payload.status),
        updatedOrderStatus: action.payload.status,
        error: null,
      };
    case APPROVE_ORDER_STATUS_FAILURE:
      return {
        ...state,
        updatedOrderStatus: 0,
        error: action.payload.error,
      };
    case UPDATE_ORDER_STATUS_SUCCESS:
      return {
        ...state,
        order: updateOrderStatus(state.order, action.payload.status),
        updatedOrderStatus: action.payload.status,
        error: null,
      };
    case UPDATE_ORDER_STATUS_FAILURE:
      return {
        ...state,
        updatedOrderStatus: 0,
        error: action.payload.error,
      };
    case GET_ORDER_ADDRESSES_SUCCESS:
      return {
        ...state,
        orderAddresses: action.payload.orderAddresses,
        error: null,
      };
    case GET_ORDER_ADDRESSES_FAILURE:
      return {
        ...state,
        orderAddresses: [],
        error: action.payload.error,
      };
    case SAVE_ORDERS_SERVICE_SETTINGS_SUCCESS:
      return {
        ...state,
        saveOrderServiceSettingsStatus: action.payload.saveOrderServiceSettingsStatus,
        error: null,
      };
    case SAVE_ORDERS_SERVICE_SETTINGS_FAILURE:
      return {
        ...state,
        saveOrderServiceSettingsStatus: 0,
        error: action.payload.error,
      };
    case GET_ORDERS_SERVICE_SETTINGS_SUCCESS:
      return {
        ...state,
        orderServiceSettingsData: action.payload.orderServiceSettingsData,
        error: null,
      };
    case GET_ORDERS_SERVICE_SETTINGS_FAILURE:
      return {
        ...state,
        orderServiceSettingsData: null,
        error: action.payload.error,
      };
    case GET_ORDER_STATUS_SUCCESS:
      return {
        ...state,
        getOrderStatus: action.payload.getOrderStatus,
      };
    case GET_ORDER_STATUS_FAILURE:
      return {
        ...state,
        getOrderStatus: action.payload.getOrderStatus,
      };
    case UPDATE_ORDER_ENTITY_SUCCESS:
      return {
        ...state,
        order: action.payload.order
      };
    case UPDATE_ORDER_ENTITY_FAILURE:
      return {
        ...state,
        error: action.payload.error
      };
    default:
      return state;
  }
};

export default orders;
