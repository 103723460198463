import React, { createContext, useReducer, useContext } from "react";
// import * as actionTypes from "@/constants/actionTypes";

const initialState = {};

export const reducer = (state = initialState, action) => {
  let nextState;

  switch (action.type) {
    default:
      nextState = state;
  }

  if (process.env.NODE_ENV !== "production") {
    console.groupCollapsed(action.type);
    console.log("prev state", state);
    console.log("action", action);
    console.log("next state", nextState);
    console.groupEnd();
  }

  return nextState;
};

export const StateContext = createContext();

export const StateProvider = ({ children }) => (
  <StateContext.Provider value={useReducer(reducer, initialState)}>
    {children}
  </StateContext.Provider>
);

export const useStateValue = () => useContext(StateContext);
