import {
  DELETE_PRODUCT_FAILURE,
  DELETE_PRODUCT_SUCCESS,
  GET_PRODUCT_FAILURE,
  GET_PRODUCT_STATUS_FAILURE,
  GET_PRODUCT_STATUS_SUCCESS,
  GET_PRODUCT_SUCCESS,
  GET_PRODUCTS_CATALOG_FAILURE,
  GET_PRODUCTS_CATALOG_SUCCESS,
  GET_PRODUCTS_FAILURE,
  GET_PRODUCTS_REQUEST,
  GET_PRODUCTS_SUCCESS,
  UPDATE_PRODUCT_FAILURE,
  UPDATE_PRODUCT_SUCCESS,
} from "@/constants/actionTypes";
import { ProductsActionsTypes } from "@/actions/products.actions";
import { ProductType } from "app/types";

type InitialStateType = {
  productsList: ProductType[];
  product: ProductType;
  error: string;
  isFetching: boolean;
  productsListPages: number;
  updateProductStatus: number;
  deleteProductStatus: number;
  productsCatalogList: ProductType[];
  productsCatalogListPages: number;
  getProductStatus: number;
};

const initialState: InitialStateType = {
  productsList: [],
  product: null,
  error: null,
  isFetching: true,
  productsListPages: 0,
  updateProductStatus: 0,
  deleteProductStatus: 0,
  productsCatalogList: [],
  productsCatalogListPages: 0,
  getProductStatus: 0,
};

const products = (
  state = initialState,
  action: ProductsActionsTypes
): InitialStateType => {
  switch (action.type) {
    case GET_PRODUCTS_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: null
      };
    case GET_PRODUCTS_SUCCESS:
      return {
        ...state,
        productsList: action.payload.productsList.records,
        productsListPages: action.payload.productsList.pages,
        updateProductStatus: 0,
        deleteProductStatus: 0,
        error: null,
        isFetching: false,
      };
    case GET_PRODUCTS_FAILURE:
      return {
        ...state,
        productsList: [],
        productsListPages: 0,
        error: action.payload.error,
        isFetching: false,
      };
    case GET_PRODUCT_SUCCESS:
      return {
        ...state,
        product: action.payload.product,
        updateProductStatus: 0,
        deleteProductStatus: 0,
        error: null,
      };
    case GET_PRODUCT_FAILURE:
      return {
        ...state,
        product: null,
        getProductStatus: 0,
        error: action.payload.error,
      };
    case UPDATE_PRODUCT_SUCCESS:
      return {
        ...state,
        updateProductStatus: action.payload.updateProductStatus,
        error: null,
      };
    case UPDATE_PRODUCT_FAILURE:
      return {
        ...state,
        updateProductStatus: 0,
        error: action.payload.error,
      };
    case DELETE_PRODUCT_SUCCESS:
      return {
        ...state,
        deleteProductStatus: action.payload.deleteProductStatus,
        error: null,
      };
    case DELETE_PRODUCT_FAILURE:
      return {
        ...state,
        deleteProductStatus: 0,
        error: action.payload.error,
      };
    case GET_PRODUCTS_CATALOG_SUCCESS:
      return {
        ...state,
        productsCatalogList: action.payload.productsCatalogList.records,
        productsCatalogListPages: action.payload.productsCatalogList.pages,
        error: null,
        isFetching: false,
      };
    case GET_PRODUCTS_CATALOG_FAILURE:
      return {
        ...state,
        productsCatalogList: [],
        error: action.payload.error,
        isFetching: false,
      };
    case GET_PRODUCT_STATUS_SUCCESS:
      return {
        ...state,
        getProductStatus: action.payload.getProductStatus,
      };
    case GET_PRODUCT_STATUS_FAILURE:
      return {
        ...state,
        getProductStatus: action.payload.getProductStatus,
      };
    default:
      return state;
  }
};

export default products;
