import React from "react";
import { createStore, applyMiddleware, compose } from "redux";
import { Provider } from "react-redux";
import thunk from "redux-thunk";
import mainReducer from "@/reducers";
import { setupResponseInterceptor } from "@/utils/axios";

const windowGlobal = typeof window !== "undefined" && window;

export default ({ element }) => {
  const enhancer = compose(
    applyMiddleware(thunk),
    windowGlobal.__REDUX_DEVTOOLS_EXTENSION__
      ? windowGlobal.__REDUX_DEVTOOLS_EXTENSION__()
      : (f) => f
  );
  const store = createStore(mainReducer, {}, enhancer);

  setupResponseInterceptor(store);

  return <Provider store={store}>{element}</Provider>;
};
