import {
  GET_LOGS_EVENTS_SUCCESS,
  GET_LOGS_EVENTS_FAILURE,
  GET_EVENTS_TYPES_SUCCESS,
  GET_EVENTS_TYPES_FAILURE,
} from "@/constants/actionTypes";
import { LogsActionsTypes } from "@/actions/logs.actions";
import {
  EventTypesType,
  LogsEventsListType,
} from "app/types";

type InitialStateType = {
  logEvents: LogsEventsListType[];
  eventsTypes: EventTypesType[];
  logEventsPages: number;
  error: string;
};

const initialState: InitialStateType = {
  logEvents: [],
  eventsTypes: [],
  logEventsPages: 0,
  error: null,
};

const logs = (
  state = initialState,
  action: LogsActionsTypes
): InitialStateType => {
  switch (action.type) {
    case GET_LOGS_EVENTS_SUCCESS:
      return {
        ...state,
        logEvents: action.payload.logEvents.records,
        logEventsPages: action.payload.logEvents.pages,
        error: null,
      };
    case GET_LOGS_EVENTS_FAILURE:
      return {
        ...state,
        logEvents: [],
        error: action.payload.error,
      };
    case GET_EVENTS_TYPES_SUCCESS:
      return {
        ...state,
        eventsTypes: action.payload.eventsTypes,
        error: null,
      };
    case GET_EVENTS_TYPES_FAILURE:
      return {
        ...state,
        eventsTypes: [],
        error: action.payload.error,
      };
    default:
      return state;
  }
};

export default logs;
