import {
  SERVICES_REQUEST,
  SERVICES_SUCCESS,
  SERVICES_FAILURE,
} from "@/constants/actionTypes";
import { ServicesActionsTypes } from "@/actions/services.actions";
import { ServiceType } from "app/types";

type InitialStateType = {
  isFetching: boolean;
  error: string | null;
  servicesData: ServiceType[];
};

const initialState: InitialStateType = {
  isFetching: false,
  error: null,
  servicesData: [],
};

const services = (
  state = initialState,
  action: ServicesActionsTypes
): InitialStateType => {
  switch (action.type) {
    case SERVICES_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    case SERVICES_SUCCESS:
      return {
        ...state,
        isFetching: false,
        servicesData: action.payload.services,
      };
    case SERVICES_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
};

export default services;
