import {
  GET_OKATO_FAILURE,
  GET_OKATO_SUCCESS,
  IMPORT_OKATO_FAILURE,
  IMPORT_OKATO_SUCCESS,
} from "@/constants/actionTypes";
import { OkatoActionsTypes } from "@/actions/okato.actions";
import { OkatoType } from "app/types";

type InitialStateType = {
  okato: OkatoType[];
  okatoListPages: number;
  error: string;
  okatoImportStatus: number;
  okatoImportError: string;
};

const initialState: InitialStateType = {
  okato: [],
  okatoListPages: 1,
  error: null,
  okatoImportStatus: 0,
  okatoImportError: null,
};

const okato = (
  state = initialState,
  action: OkatoActionsTypes
): InitialStateType => {
  switch (action.type) {
    case GET_OKATO_SUCCESS:
      return {
        ...state,
        okato: action.payload.okato.records,
        okatoListPages: action.payload.okato.pages,
        error: null,
      };
    case GET_OKATO_FAILURE:
      return {
        ...state,
        okato: [],
        error: action.payload.error,
      };
    case IMPORT_OKATO_SUCCESS:
      return {
        ...state,
        okatoImportStatus: action.payload.okatoImportStatus,
        okatoImportError: null,
      };
    case IMPORT_OKATO_FAILURE:
      return {
        ...state,
        okatoImportStatus: 0,
        okatoImportError: action.payload.okatoImportError,
      };
    default:
      return state;
  }
};

export default okato;
