import {
  CREATE_SUPPLIER_FAILURE,
  CREATE_SUPPLIER_STATUS_FAILURE,
  CREATE_SUPPLIER_STATUS_SUCCESS,
  CREATE_SUPPLIER_SUCCESS,
  DELETE_SUPPLIER_FAILURE,
  DELETE_SUPPLIER_SUCCESS,
  GET_SUPPLIER_FAILURE,
  GET_SUPPLIER_SERVICE_SETTINGS_FAILURE,
  GET_SUPPLIER_SERVICE_SETTINGS_SUCCESS,
  GET_SUPPLIER_SUCCESS,
  SAVE_SUPPLIER_SERVICE_SETTINGS_FAILURE,
  SAVE_SUPPLIER_SERVICE_SETTINGS_SUCCESS,
  SUPPLIERS_FAILURE,
  SUPPLIERS_SUCCESS,
  UPDATE_CURRENT_SUPPLIER_SUCCESS,
  UPDATE_SUPPLIER_FAILURE,
  UPDATE_SUPPLIER_SUCCESS,
} from "@/constants/actionTypes";
import {SuppliersActionsType} from "@/actions/suppliers.actions";

import {
  FilterSelectType,
  SupplierFacetsType,
  SupplierModelType,
  SupplierServiceSettingsDataType,
  SupplierType,
} from "app/types";

export type InitialStateType = {
  isFetching: boolean;
  isFetchingFacets: boolean;
  isFetchingCategories: boolean;
  error: string | null;
  supplier: SupplierModelType;
  suppliersPages: number;
  suppliersCategories: any;
  suppliers: SupplierModelType[];
  supplierTypes: SupplierType[];
  supplierFacets: SupplierFacetsType[];
  tabNameActive: SupplierFacetsType;
  region: FilterSelectType;
  newSuppler: SupplierModelType;
  updateSupplerStatus: number;
  deleteSupplerStatus: number;
  createSupplierStatus: number;
  supplierName: string;
  saveSupplierServiceSettingsStatus: number;
  supplierServiceSettingsData: SupplierServiceSettingsDataType;
};

const initialState: InitialStateType = {
  isFetching: true,
  isFetchingFacets: false,
  isFetchingCategories: false,
  error: null,
  supplier: null,
  suppliers: [],
  suppliersCategories: [],
  supplierTypes: [],
  supplierFacets: [],
  tabNameActive: null,
  region: { value: null, label: "" },
  newSuppler: null,
  updateSupplerStatus: 0,
  deleteSupplerStatus: 0,
  suppliersPages: 1,
  createSupplierStatus: 0,
  supplierName: '',
  saveSupplierServiceSettingsStatus: 0,
  supplierServiceSettingsData: null,
};

const suppliers = (
  state = initialState,
  action: SuppliersActionsType
): InitialStateType => {
  switch (action.type) {
    case SUPPLIERS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        suppliers: action.payload.suppliers.records,
        suppliersPages: action.payload.suppliers.pages,
      };
    case SUPPLIERS_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload.error,
        suppliers: [],
      };
    case GET_SUPPLIER_SUCCESS:
      return {
        ...state,
        supplier: action.payload.supplier,
        error: null,
      };
    case GET_SUPPLIER_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        supplier: null,
      };
    case CREATE_SUPPLIER_SUCCESS:
      return {
        ...state,
        newSuppler: action.payload.newSuppler,
      };
    case CREATE_SUPPLIER_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        newSuppler: null,
      };
    case UPDATE_SUPPLIER_SUCCESS:
      return {
        ...state,
        error: null,
        updateSupplerStatus: action.payload.updateSupplerStatus,
      };
    case UPDATE_SUPPLIER_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        updateSupplerStatus: 0,
      };
    case DELETE_SUPPLIER_SUCCESS:
      return {
        ...state,
        error: null,
        deleteSupplerStatus: action.payload.deleteSupplerStatus,
      };
    case DELETE_SUPPLIER_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        deleteSupplerStatus: 0,
      };
    case CREATE_SUPPLIER_STATUS_SUCCESS:
      return {
        ...state,
        error: null,
        createSupplierStatus: action.payload.createSupplierStatus,
      };
    case CREATE_SUPPLIER_STATUS_FAILURE:
      return {
        ...state,
        createSupplierStatus: 0,
      };
    case UPDATE_CURRENT_SUPPLIER_SUCCESS:
      return {
        ...state,
        supplierName: action.payload.supplierName
      };
    case SAVE_SUPPLIER_SERVICE_SETTINGS_SUCCESS:
      return {
        ...state,
        saveSupplierServiceSettingsStatus: action.payload.saveSupplierServiceSettingsStatus
      };
    case SAVE_SUPPLIER_SERVICE_SETTINGS_FAILURE:
      return {
        ...state,
        saveSupplierServiceSettingsStatus: 0,
        error: action.payload.error
      };
    case GET_SUPPLIER_SERVICE_SETTINGS_SUCCESS:
      return {
        ...state,
        supplierServiceSettingsData: action.payload.supplierServiceSettingsData,
        error: null,
      };
    case GET_SUPPLIER_SERVICE_SETTINGS_FAILURE:
      return {
        ...state,
        supplierServiceSettingsData: null,
        error: action.payload.error,
      };
    default:
      return state;
  }
};

export default suppliers;
